const ufs = [
    {'text': 'Acre', 'value': 'AC'},
    {'text': 'Alagoas', 'value': 'AL'},
    {'text': 'Amapá', 'value': 'AP'},
    {'text': 'Amazonas', 'value': 'AM'},
    {'text': 'Bahia', 'value': 'BA'},
    {'text': 'Ceará', 'value': 'CE'},
    {'text': 'Distrito Federal', 'value': 'DF'},
    {'text': 'Espírito Santo', 'value': 'ES'},
    {'text': 'Goiás', 'value': 'GO'},
    {'text': 'Maranhão', 'value': 'MA'},
    {'text': 'Mato Grosso', 'value': 'MT'},
    {'text': 'Mato Grosso do Sul', 'value': 'MS'},
    {'text': 'Minas Gerais', 'value': 'MG'},
    {'text': 'Pará', 'value': 'PA'},
    {'text': 'Paraíba', 'value': 'PB'},
    {'text': 'Paraná', 'value': 'PR'},
    {'text': 'Pernambuco', 'value': 'PE'},
    {'text': 'Piauí', 'value': 'PI'},
    {'text': 'Rio de Janeiro', 'value': 'RJ'},
    {'text': 'Rio Grande do Norte', 'value': 'RN'},
    {'text': 'Rio Grande do Sul', 'value': 'RS'},
    {'text': 'Rondônia', 'value': 'RO'},
    {'text': 'Roraima', 'value': 'RR'},
    {'text': 'Santa Catarina', 'value': 'SC'},
    {'text': 'São Paulo', 'value': 'SP'},
    {'text': 'Sergipe', 'value': 'SE'},
    {'text': 'Tocantins', 'value': 'TO'}
];

export default ufs;