
<template>
    <div style="display: flex; justify-content: space-around;">
        <span v-if="tries > 0" style="width: 80%; padding: 10px;">
            Verificação automática de pagamento em <b>{{ timer }} segundos...</b>
        </span>
        <a v-else @click="refresh" style="width: 80%; padding: 10px;">
            Clique aqui para verificar o pagamento
        </a>
    </div>
</template>

<script>

export default {
    name: 'Timer',

    data: () => ({
        interval: null,
        timer: 30,
        tries: 12,
    }),

    props: ['value'],

    mounted() {
        this.startTimer();
    },

    beforeDestroy() {
        clearInterval(this.interval);
    },

    methods: {
        startTimer() {
           this.interval = setInterval(() => {
                if (this.timer <= 0) {
                    (this.tries <= 0) && clearInterval(this.interval);

                    this.timer = 30;
                    this.tries -= 1;

                    this.$emit('trigger');
                }

                this.timer -= 1;
            }, 1000);
        },

        refresh() {
            location.reload();
        },
    },
}
</script>

<style scoped>
</style>
