
<template>
    <div>
        <div class="qrcode-container">
            <div class="gray-box">
                <div class="white-box">
                    <v-img
                        v-if="getSrc"
                        :src="getSrc"
                    />
                </div>
            </div>
        </div>
        <div class="ml-4">
            <br>
            <div style="display: flex; justify-content: center;">
                <v-btn
                    @click="$emit('copyCode')"
                    color="blue"
                    class="white--text mb-2"
                >
                    <v-icon class="mr-2">mdi-content-copy</v-icon>
                    COPIAR CÓDIGO
                </v-btn>
            </div>
            <br>
            <div style="display: flex; justify-content: space-around;">
                <ul style="list-style: none; text-align: left;">
                    <li>
                        <span>1. <b>Abra o aplicativo ou Internet Banking</b> para pagar</span>
                    </li>
                    <li>
                        <span>2. Na opção Pix, escolha <b>'Ler QR Code'</b></span>
                    </li>
                    <li>
                        <span>3. <b>Leia o QR Code</b> ou, se preferir, <b>copie o código para Pix Copia e Cola.</b></span>
                    </li>
                    <li>
                        <span>4. Revise as informações e <b>confirme o pagamento.</b></span>
                    </li>
                </ul>
            </div>
            <br>
            <span v-if="pixExpiration" style="font-size: 12px;">
                <!-- Você tem até <b>{{ pixExpiration }}</b> para pagar. Depois disso este pedido será cancelado automaticamente. -->
                Você tem <b>30 minutos</b> para pagar. Depois disso este pedido será cancelado automaticamente.
                </span>
            <br>
        </div>
    </div>
</template>

<script>

export default {
    name: 'QrCodePix',

    data: () => ({
        //
    }),

    props: ['value'],

    computed: {
        pixExpiration() {
            const expiration = this.value
                ? this.moment(this.value.date_of_expiration).format('DD/MM/YYYY HH:mm')
                : '';

            return expiration;
        },

        getSrc() {
            const base64 = this.value?.point_of_interaction?.transaction_data?.qr_code_base64;
            return base64 ? `data:image/png;base64,${base64}` : null;
        },
    },
}
</script>

<style scoped>
.gray-box {
    background-color: #ddd;
    border-radius: 5px;
    height: 230px;
    width: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.white-box {
    background-color: #fff;
    height: 200px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.qrcode-container {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    min-width: 50%;
}
</style>
