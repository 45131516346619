<template>
    <BaseContainer>
        <span slot="menu">
            <v-app-bar app dark>
                <v-btn icon @click="onClickGoBack">
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <v-spacer />
                <span style="text-transform: uppercase; font-weight: bold;">
                    Pedido #{{ order.id }}
                </span>
                <v-spacer />
                <Account
                    v-if="false"
                    @login="showLoginDialog = true"
                    @logout="findOrder"
                />
            </v-app-bar>
        </span>
        <span slot="principal">
            <v-content class="text-center mb-8" style="background: #ddd; height: 100%;">
                <div>
                    <v-chip
                        class="ma-4"
                        :color="chip.color"
                        large
                        label
                    >
                        <v-icon>mdi-{{ chip.icon }}</v-icon>
                        <span class="ml-2">{{ chip.text }}</span>
                    </v-chip>
                </div>

                <div>
                    <v-chip
                        v-if="statusDetail"
                        class="ma-2"
                        color="error"
                        height="200"
                        large
                        label
                    >
                        <v-icon>mdi-close-circle</v-icon>
                        <span class="ml-2" v-html="statusDetail"/>
                    </v-chip>
                </div>

                <QrCodePix
                    v-if="isPix && status == 'pending'"
                    class="mt-6"
                    v-model="pixPayment"
                    @copyCode="copyPix"
                />

                <CartaoCredito
                    v-if="isCreditCard && status == 'pending'"
                    v-model="order"
                    @pay="onCreditCardPayment"
                />

                <CreateAccount
                    v-if="showCreateAccountDialog"
                    v-model="order.whatsapp"
                    @create="onCreateAccount"
                />

                <Login
                    v-if="showLoginDialog"
                    v-model="order.whatsapp"
                    @login="onLogin"
                />

                <div v-if="status == 'approved'" class="mt-4">
                    <h4>
                        Clique no botão abaixo para enviar o pedido no WhatsApp e acompanhar o andamento!
                    </h4>
                    <v-btn
                        color="#1CBB22"
                        class="white--text mt-4"
                        @click="sendWhatsapp"
                        :loading="loading"
                        x-large
                    >
                        <v-icon>mdi-whatsapp</v-icon>&nbsp;
                        Enviar no WhatsApp
                    </v-btn>
                </div>

                <Timer
                    v-if="isPix && status == 'pending'"
                    @trigger="onTimerTrigger"
                    class="mt-4"
                />
            </v-content>
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer';
import Timer from './Timer';
import QrCodePix from './QrCodePix';
import CartaoCredito from './CartaoCredito';
import CreateAccount from '@/pages/auth/CreateAccount';
import Login from '@/pages/auth/Login';
import { io } from 'socket.io-client';
import { mapMutations, mapActions } from 'vuex';
import Account from '../auth/Account';

export default {
    name: 'Pagamento',

    components: {
        BaseContainer,
        Timer,
        QrCodePix,
        CartaoCredito,
        CreateAccount,
        Login,
        Account,
    },

    data: () => ({
        pixPayment: null,
        hasAccount: false,
        order: {},
        emp: {},
        socket: null,
        loading: false,
        showCreateAccountDialog: false,
        showLoginDialog: false,
    }),

    computed: {
        status() {
            if (!this.order.pagamentos?.length) {
                return 'pending';
            }

            const pgto = this.order.pagamentos.find(e => e.pagamento_online);

            if (!pgto.pagamento_online?.payment) {
                return 'pending';
            }

            return pgto.pagamento_online.payment.status;
        },

        statusDetail() {
            if (!this.order.pagamentos?.length) {
                return '';
            }

            // eslint-disable-next-line
            const pgto = this.order.pagamentos.reverse().find(e => e.pagamento_online);

            if (!pgto.pagamento_online?.preference) {
                return '';
            }

            const status = pgto.pagamento_online.preference.status_detail;

            if (status == 'cc_rejected_insufficient_amount') {
                return 'Falha no pagamento <br>Limite insuficiente no cartão';
            }

            return '';
        },

        chip() {
            let chip = {
                color: 'info',
                text: 'Aguardando pagamento',
                icon: 'information',
            };

            if (this.status == 'approved') {
                chip = {
                    color: 'success',
                    text: 'Pagamento aprovado',
                    icon: 'check-circle'
                };
            }

            if (this.status == 'rejected') {
                chip = {
                    color: 'error',
                    text: 'Pagamento rejeitado',
                    icon: 'close-circle'
                };
            }

            return chip;
        },

        isPix() {
            return this.order.forma_pagamento == 3;
        },

        isCreditCard() {
            return [2, 4].includes(this.order.forma_pagamento)
        },
    },

    async mounted() {
        await this.findOrder();
        this.ws();
    },

    methods: {
        ...mapMutations({
            setCardPaymentLoading: 'setCardPaymentLoading',
        }),

        ...mapActions([
            'userAuthenticate',
        ]),

        ws() {
            const { uuid } = this.order;
            const isLocalHost = document.URL.indexOf('localhost') !== -1;

            if (isLocalHost) {
                // return;
            }

            if (this.socket || this.status != 'pending') {
                return;
            }

            this.socket = isLocalHost ? io('http://localhost:3000') : io();

            this.socket.on('connect', () => {
                uuid && this.socket.emit('order-payment', uuid);
            });

            this.socket.on('payment', () => {
                location.reload();
            });
        },

        copyPix() {
            const { qr_code } = this.pixPayment.point_of_interaction.transaction_data;
            navigator.clipboard.writeText(qr_code).then(
                () => this.notify('Código copiado!'),
                () => this.notify('Falha ao copiar!', 'warning')
            );
        },

        async findOrder() {
            await this.$http.get(`pedido/${this.$route.params.pedUuid}`).then(resp => {
                const data = resp.data.data;
                const { pedido, empresa, hasAccount } = data;

                if (data.type == 'warning') {
                    this.notify(data.msg, 'warning');
                    return;
                }

                this.order = pedido;
                this.emp = empresa;
                this.hasAccount = hasAccount;

                window.$mpPublicKey = empresa.mpPublicKey;

                this.setPayment();
                // this.auth();
            })
            .catch((e) => {
                console.log(e)
                this.notify('Não foi possivel concluir a operação', 'warning');
            });
        },

        auth() {
            if (!this.isCreditCard || this.status != 'pending') {
                return;
            }

            if (this.hasAccount) {
                const name = this.$http.defaults.headers.common['emp'] + '-access-token';
                const accessToken = localStorage.getItem(name);

                if (!accessToken) {
                    this.showLoginDialog = true;
                }
            } else {
                this.showCreateAccountDialog = true;
            }
        },

        setPayment() {
            const pagamentos = this.order.pagamentos;

            if (!pagamentos?.length) {
                return;
            }

            const { pagamento_online: payment } = this.order.pagamentos.find(e => e.pagamento_online);

            this.pixPayment = payment.preference;
        },

        async onCreditCardPayment(data) {
            const pedUuid = this.$route.params.pedUuid;

            await this.$http.post(`pedido/${pedUuid}/pagamento`, data).then(resp => {
                const data = resp.data;

                if (data.type == 'warning') {
                    this.notify(data.msg, 'warning');
                    return;
                }

                this.findOrder();
            })
            .catch((e) => {
                console.log(e)
                this.notify('Não foi possivel concluir a operação', 'warning');
            })
            .finally(() => {
                this.setCardPaymentLoading(false);
            });
        },

        async sendWhatsapp() {
            this.loading = true;

            await this.$http.get(`pedido/${this.emp.uuid}/${this.order.uuid}`).then(resp => {
                const { phone, text } = resp.data.data;

                this.phone = phone;
                this.text = window.encodeURIComponent(text);
                this.loading = false;

                window.open(`whatsapp://send?phone=${this.phone}&text=${this.text}`);
            });
        },

        onTimerTrigger() {
            this.findOrder();
        },

        async onClickGoBack() {
            if (await this.$root.$confirm('Confirmação', 'Deseja realmente voltar e cancelar o pedido?', { color: 'red darken-1' })) {

                this.$router.push(`/${this.$route.params.emp}`);

                // TODO: cancelar pedido
                // this.$http.post(`pedidos/xxxx`).then(() => {
                //     //
                // }).catch(() => {
                //     this.notify('Não foi possivel concluir a operação', 'warning');
                // });
            }
        },

        onCreateAccount() {
            this.showCreateAccountDialog = false;
            this.userAuthenticate();
            this.notify('Conta criada com sucesso');
        },

        onLogin() {
            this.showLoginDialog = false;
            this.notify('Login efetuado com sucesso');
        },

        onForgotPassword() {
            this.showPasswordResetDialog = true;
        },

        onReset() {
            //
        },

        showLogin() {
            this.showPasswordResetDialog = false;
            this.showLoginDialog = true;
        },
    },
}
</script>

<style scoped>
</style>
