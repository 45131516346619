
<template>
    <v-card class="ma-2" elevation="12">
        <v-card-text style="display: flex; flex-direction: column; align-items: center;">
            <h1 class="mb-6">{{ getTitle }}</h1>
            <div v-if="!addCard && hasCards" class="mb-6">
                <v-card
                    v-for="(card, i) in userCards"
                    :key="i"
                    @click="onCardSelect(card)"
                    max-width="400"
                    elevation="6"
                >
                    <v-card-text style="display: flex; align-items: center;">
                        <v-icon v-if="card.selected" color="green">mdi-check-circle</v-icon>
                        <v-icon v-else>mdi-circle-outline</v-icon>
                        <v-img
                            height="20"
                            width="60"
                            contain
                            :src="card.payment_method.secure_thumbnail"
                        />
                        <div>
                            <v-card-subtitle style="text-transform: uppercase; padding: 2px;">
                                <b>{{ card.first_six_digits }} • • • • </b>
                            </v-card-subtitle>
                            <v-card-subtitle style="text-transform: uppercase; padding: 2px;">
                                <b>{{ card.cardholder.name }}</b>
                            </v-card-subtitle>
                        </div>
                    </v-card-text>
                </v-card>
            </div>
            <div>
               <v-btn
                    v-if="!addCard && hasCards"
                    @click="addCard = true"
                    large
                    text
                >+ Adicionar Outro Cartão</v-btn>
            </div>

            <v-card v-if="addCard || !hasCards" width="600" class="ma-2">
                <v-card-text>
                    <v-form ref="form">
                        <v-row>
                            <v-col>
                                <div v-if="hasCards" style="display: flex; align-items: center;">
                                    <v-btn
                                        @click="addCard = false"
                                        icon
                                    >
                                        <v-icon>mdi-chevron-left</v-icon>
                                    </v-btn>
                                </div>
                                <div style="display: flex; align-items: center;" class="mt-2">
                                    <div style="font-size: 12px; text-align: left; font-weight: bold; line-height: 15px;">
                                        Cartões Aceitos:
                                    </div>
                                    <v-img
                                        v-for="brand in ['mastercard', 'visa', 'elo', 'amex', 'hipercard']"
                                        :key="brand"
                                        height="15"
                                        width="40"
                                        contain
                                        :src="require(`@/assets/${brand}.png`)"
                                    />
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    label="Titular do Cartão"
                                    v-model="card.name"
                                    :rules="[rules.empty]"
                                    outlined
                                    dense
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    label="Número do Cartão"
                                    type="tel"
                                    v-model="card.number"
                                    v-mask="[cardMask]"
                                    :rules="[rules.empty]"
                                    outlined
                                    dense
                                    hide-details
                                />
                                <div style="text-align: left;">{{ cardType }}</div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6">
                                Validade do Cartão
                                <div style="display: flex;">
                                    <v-select
                                        v-model="card.expirationMonth"
                                        :items="months"
                                        label="Mês"
                                        :rules="[rules.empty]"
                                        outlined
                                        dense
                                        hide-details
                                    />
                                    <v-select
                                        v-model="card.expirationYear"
                                        :items="years"
                                        label="Ano"
                                        :rules="[rules.empty]"
                                        outlined
                                        dense
                                        hide-details
                                    />
                                </div>
                            </v-col>
                            <v-col cols="12" sm="6" style="display: flex; align-items: flex-end;">
                                <v-text-field
                                    v-model="card.cvv"
                                    label="CVV"
                                    type="tel"
                                    :rules="[rules.empty]"
                                    v-mask="['#####']"
                                    outlined
                                    dense
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <!-- <v-row>
                            <v-col style="margin-top: -25px;">
                                <v-checkbox
                                    v-model="saveCard"
                                    label="Salvar cartão para os próximos pedidos"
                                    class="save-card"
                                    dense
                                    hide-details
                                />
                            </v-col>
                        </v-row> -->
                        <v-row>
                            <v-col style="display: flex; align-items: center;">
                                <v-img
                                    :src="require('@/assets/mercadopago-hands.png')"
                                    height="25"
                                    width="25"
                                    contain
                                    class="mr-2"
                                />
                                <div style="font-size: 12px; text-align: left; width: 95%;">
                                    Pagamento protegido por Mercado Pago.
                                </div>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>

            <div v-if="chip.text">
                <v-chip
                    class="ma-4"
                    :color="chip.color"
                    large
                    label
                    outlined
                >
                    <v-icon>mdi-{{ chip.icon }}</v-icon>
                    <span class="ml-2">{{ chip.text }}</span>
                </v-chip>
            </div>
            <v-btn
                x-large
                color="#1fc625"
                block
                class="white--text mt-8"
                :loading="cardPaymentLoading"
                @click="pagar"
            >Pagar R${{ formatPrice(value.total) }}</v-btn>

        </v-card-text>
    </v-card>
</template>

<script>
import ufs from '@/utils/ufs';
import { mapState, mapMutations } from 'vuex';
import { installMercadoPago, getPaymentToken } from '@/plugins/mercadoPago';

export default {
    name: 'CartaoCredito',

    data: () => ({
        addCard: false,
        saveCard: true,
        loading: false,
        months: [],
        years: [],
        card: {},
        rules: {
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'E-mail inválido'
            },

            empty: value => !!value || 'Preenchimento obrigatório',

            fullName: value => (value || '').trim().split(' ').length > 1 || 'Informe o nome completo',

            cpf: value => value?.length == 14 || 'Cpf incompleto',
        },
        chip: {
            color: '',
            text: '',
            icon: '',
        },
        cards: {
            visa: 'visa',
            master: 'master',
            amex: 'amex',
            hipercard: 'hipercard',
        }
    }),

    props: ['value'],

    mounted() {
        installMercadoPago();

        this.fillOptions();
    },

    computed: {
        ...mapState([
            'usuario',
            'cardPaymentLoading',
        ]),

        cardType() {
            const number = (this.card.number || '').replace(/\s/g, '');

            if (number.match(new RegExp('^(410390|410391|410392|485464|452204|409869|496046)')) != null) {
                return 'debvisa';
            }

            if (number.match(new RegExp('^(4|46348800)')) != null) {
                return 'visa';
            }

            if (number.match(new RegExp('^(34|37)')) != null) {
                return 'amex';
            }

            if (number.match(new RegExp('^(532884)')) != null) {
                return 'master';
            }

            if (number.match(new RegExp('^5[1-5]')) != null) {
                return 'master';
            }

            if (number.match(new RegExp('^(502121|536106)')) != null) {
                return 'debmaster';
            }

            if (number.match(new RegExp('^(523431)')) != null) {
                return 'maestro';
            }

            if (number.match(new RegExp('^(506722)')) != null) {
                return 'debelo';
            }

            if (number.match(new RegExp('^((606282)|(637095)|(637568)|(637599)|(637609)|(637612))')) != null) {
                return 'hipercard ';
            }

            return ''; // default type
        },

        cardMask() {
            return this.cardType === 'amex'
                ? '#### ###### #####'
                : '#### #### #### ####';
        },

        userCards() {
            const cards = this.usuario.cards;

            cards.forEach(card => {
                card.selected = false;
            });

            return cards;
        },

        hasCards() {
            return this.usuario.cards?.length;
        },

        getTitle() {
            return this.hasCards ? 'Como será o pagamento?' : 'Informe seus Dados de Pagamento';
        },
    },

    methods: {
        ...mapMutations({
            setCardPaymentLoading: 'setCardPaymentLoading',
        }),

        fillOptions() {
            this.ufs = ufs;
            this.months = [
                '01', '02', '03', '04', '05', '06',
                '07', '08', '09', '10', '11', '12',
            ];
            const year = new Date().getFullYear();
            for (let i = 0; i < 10; i++) {
                this.years.push((year + i).toString());
            }
        },

        async pagar() {
            // if (!this.usuario.id) {
            //     this.notify('Faça login para continuar', 'warning');
            //     return;
            // }

            if (this.addCard && !this.$refs.form.validate()) {
                this.notify('Preencha todos os campos', 'warning');
                return;
            }

            if (this.addCard && !this.cardType) {
                this.notify('Desculpe, ainda não aceitamos a bandeira do seu cartão', 'warning');
                return;
            }

            const card = this.addCard || !this.hasCards
                ? {
                    cardholderName: this.card.name,
                    cardNumber: this.card.number?.replace(/\s/g, ''),
                    cardExpirationMonth: this.card.expirationMonth,
                    cardExpirationYear: this.card.expirationYear,
                    securityCode: this.card.cvv,
                }
                : {
                    cardId: this.getCardId(),
                };

            this.setCardPaymentLoading(true);

            await getPaymentToken(card).then(token => {
                this.$emit('pay', { token, cardType: this.cardType});
            })
            .catch(e => {
                this.setCardPaymentLoading(false);
                console.log(e);
            });
        },

        getCardId() {
            if (this.usuario.cards?.length === 1) {
                return this.usuario.cards[0].id;
            }

            const { id } = this.usuario.cards.find(c => c.selected);
            return id;
        },

        onCardSelect(card) {
            this.usuario.cards.forEach(c => {
                c.selected = c.id === card.id;
            });
            this.$forceUpdate();
        },

        // getCardIcon(card) {
            // const brand = this.cards[card.payment_method.id];
            // return brand ? require(`@/assets/${brand}.png`) : card.payment_method.secure_thumbnail;
        // },
    },
}
</script>

<style scoped>
.total {
    font-size: 22px;
    font-weight: 600;
}

::v-deep .v-label {
    font-size: 14px;
}
</style>
